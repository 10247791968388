import {useEffect, useState} from 'react'

import addressFormatter from '@fragaria/address-formatter'
import countries        from '../lib20/countries.json'

import {useConfig} from '../lib20/KPE20context'



const getAddressArray = (address) => {

    const addressArray=addressFormatter.format(
        {
           houseNumber: address.houseNumber,
           road: address.road,
           neighbourhood: address.neighbourhood,
           city: address.city,
           postcode: address.postcode,
           countryCode: address.countryCode,
        },
        {   
            abbreviate: true,
            countryCode: address.countryCode,
            appendCountry: false,
            output: 'array'
        }
    )

    return addressArray
}

const AddressLink = (props) => {
    const {address} = props

    const config = useConfig()
    const [lang,countryOrga]=config.locale.split('-')

    const [mapLink, setMapLink] = useState('')

    const currAddressArray = getAddressArray(address)
    const countryObject=countries.find(c=>c.alpha2===address.countryCode)
    const country=countryObject ? countryObject[lang] : ''
    
    const addressLinks = {
        'GoogleMaps':      'https://www.google.com/maps/search/',
        'Here WeGo':       'https://wego.here.com/directions/mix/',
        'Openstreetmap':   'https://www.openstreetmap.org/search?query=',
        'Bing':            'https://bing.com/maps/default.aspx?where1='
    }

    const routeLinks = {
        'GoogleMaps':       'https://www.google.com/maps/dir/',
        'Here WeGo':        'https://wego.here.com/directions/mix/',
        'Bing':             'https://bing.com/maps/default.aspx?rtp=adr.'
    }

    useEffect(()=>{
        if(!config.orgaUser.Data.settings?.mapPlatform)
            setMapLink(
                addressLinks.GoogleMaps
                +currAddressArray.join(',+').replaceAll(' ', '+')
            )
        
        else if (config.orgaUser.Data.settings.mapLinkType === 'Route') {
            const userAdressArray = getAddressArray(config.orgaUser.Data.address[0])
            if(config.orgaUser.Data.settings.mapPlatform ==='Bing')
            {
                setMapLink(
                    routeLinks[config.orgaUser.Data.settings.mapPlatform]
                    +userAdressArray.join(',+').replaceAll(' ', '+')
                    +'~adr.'
                    +currAddressArray.join(',+').replaceAll(' ', '+')
                )

            }
            else
            {
                setMapLink(
                    routeLinks[config.orgaUser.Data.settings.mapPlatform]
                    +userAdressArray.join(',+').replaceAll(' ', '+')
                    +'/'
                    +currAddressArray.join(',+').replaceAll(' ', '+')
                )
            }
        }

        else 
            setMapLink(
                addressLinks[config.orgaUser.Data.settings.mapPlatform]
                +currAddressArray.join(',+').replaceAll(' ', '+')
            )
    },[address])

    return (
        <a href={mapLink} target='_blank' >{currAddressArray.join(', ')}, {country}</a>
    )
}

export default AddressLink
