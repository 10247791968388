import {useEffect, useState} from 'react'

import countries        from '../resources/countries.json'

import {useConfig} from '../lib20/KPE20context'




const MapLink = (props) => {
    const {geo,name,texts} = props

    const config = useConfig()
    const [lang,countryOrga]=config.locale.split('-')

    const [mapLink, setMapLink] = useState('')


    
    const addressLinks = {
        'GoogleMaps':      'https://www.google.com/maps/search/',
        'Here WeGo':       'https://share.here.com/l/',
        'Openstreetmap':   'http://www.openstreetmap.org/',
        'Bing':            'https://bing.com/maps/default.aspx?where1='
    }



    
        if(!config.orgaUser.Data.settings?.mapPlatform)
            return  <a
                        href={`${addressLinks.GoogleMaps}${geo.lat},${geo.lng}`}
                        target='_blank'
                     >{name}</a>
        else if(['GoogleMaps','Here WeGo','Bing'].includes(config.orgaUser.Data.settings?.mapPlatform))
            return <a
                href={`${addressLinks[config.orgaUser.Data.settings.mapPlatform]}${geo.lat},${geo.lng}`}
                target='_blank'
            >{`${config.orgaUser.Data.settings.mapPlatform} ${texts.mapLinkTo} ${name}`}</a>
        else if(config.orgaUser.Data.settings?.mapPlatform==='Openstreetmap')
        {
            return <a
                href={`${addressLinks[config.orgaUser.Data.settings.mapPlatform]}?mlat=${geo.lat}&mlng=${geo.lng}#map=14/${geo.lat}/${geo.lng}`}
                target='_blank'
                >{`${config.orgaUser.Data.settings.mapPlatform} ${texts.mapLinkTo} ${name}`}</a>

        }
}

export default MapLink
