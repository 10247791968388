import React, {useState, useEffect}  from 'react'
import {useHistory} from 'react-router-dom'
import {Icon, Menu} from 'semantic-ui-react'

const MenuEdit=(props) => {
    const history = useHistory()
    const {location} = props

    return (
        <Menu.Item onClick={e=>{history.push('/edit/'+location.UID)}}>
            <Icon name='edit'/> Bearbeiten
        </Menu.Item>
    )
}

export default MenuEdit