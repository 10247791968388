import React,{ useState, useEffect }  from 'react';
import {useHistory} from 'react-router-dom'
import {mapsApiKey} from '../config.jsx'
import {Segment} from 'semantic-ui-react'
import { MapContainer,TileLayer,Marker,Popup, ScaleControl, ZoomControl } from 'react-leaflet'
import LocationMarker  from './LocationMarker'
import {useApi,useWindowDimensions} from '@wollo-lib/kpe-context'
import { useConfig } from '../lib20/KPE20context.jsx';
import MenuFilter from './MenuFilter'
import useTexts         from '../lib20/useTexts'
import { leafletLayers, LayerSelect } from '../lib/LeafletLayers'
import '../resources/leaflet.css'

// https://dev.to/jessicabetts/how-to-use-google-maps-api-and-react-js-26c2
// https://github.com/fullstackreact/google-maps-react


const mapStyles = {
  width: '100%',
  height: '100%',
};


const LocationsMap = (props)=>  {

    const {google, filterParams,setExtraMenu, setModalParams} = props
    const config=useConfig()
    const {apiSelect}=useApi()
    const [actLocations,setLocations]= useState([])
    const {height:windowHeight}=useWindowDimensions()
    const history=useHistory()
    const texts=useTexts('/')
    const [mapLayer,setMapLayer]=useState('Osm')
    const [filter,setFilter]=useState([])
    const [types,setTypes]= useState(Object.keys(config.locationTypes))
    // const [lagerplatzFilterId,setLagerplatzFilter]=useState(uuid())            //Unter filterParams.id
    // const [hausFilterId,setHausFilter]=useState(uuid())              //ebenfalls unter filterParams.id


    // loading, reloading map locations

    useEffect(()=> {
        setExtraMenu({
            action: 'replace',
            menus:[
                {admin:false, menu:MenuFilter, props:{types,setTypes,filter,setFilter,setModalParams}}
            ]
        })
     },[filter,types,setFilter,setTypes])


    useEffect(()=>
    {
      
        const getLocations= async () => {     
            const extra=JSON.stringify([
               { path: '$.type', alias:'locationType'},
               { path: '$.public', alias:'locationPublic'},
               { path: '$.contacts', alias:'locationContacts', query:true}

            ])
           

            const result= await apiSelect(`/location/location/?filter=${JSON.stringify(filter)}&types=${JSON.stringify(types)}&Data=${extra}`)  // with filtering
            //const result= await apiSelect('/db/Locations')        // without filtering
            if(result.success)
            {
              setLocations(result.result)
            }
            else
            {
              alert(result.message)
            }
        }
        getLocations()

 
        // lösche die Lagerplatzfilter, wenn das Fenster geschlossen wird
    },[config,filter,types])


    const MapLayer= leafletLayers[mapLayer]
 
  
    return (
        <Segment attached='bottom' style={{height: windowHeight-93 +33,padding:0, marginTop:'-20px'}}>
            <MapContainer center={config.defaultMapCenter} style={mapStyles} zoom={6} scrollWheelZoom={true}>
                <ScaleControl/>
                <MapLayer/>

                <LayerSelect mapLayer={mapLayer} setMapLayer={setMapLayer} />
            
                {

                    actLocations.filter(e=>(!isNaN(e.lat) && !isNaN(e.lng)))
                    .map((location)=>{
                        return LocationMarker({
                            location,
                            config,
                            history,
                            texts
                        })
                    })

                }
                </MapContainer>
        </Segment>
    );
}


export default LocationsMap