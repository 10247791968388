import React, {useState, useEffect}  from 'react'
import {Marker,  useMapEvents} from 'react-leaflet'
import useTexts         from '../lib20/useTexts'
import SVGIcon from '../lib/SvgIcon'


const LocationMarker=(props)=>
{
 
    const { location, config}= props 
    const texts=useTexts('/')

    

 

    return(
    <Marker
        draggable
       
        position={[
            location.geo.lat ? location.geo.lat : config.defaultMapCenter.lat ,
            location.geo.lng ? location.geo.lng : config.defaultMapCenter.lng]}
       
        icon= {
            SVGIcon({
                circleText: config.locationTypes[location.type].short,
                color: config.locationTypes[location.type].color[true],
            }
        )}
    >
  
    </Marker>
    )
    

}



export default LocationMarker
