import React, {useState, useEffect, useDebugValue}  from 'react'
import {Dropdown,  Menu, Image, Icon, Flag, Sidebar, Segment, Divider, Label} from 'semantic-ui-react'
import {useHistory} from 'react-router-dom'
import {useApi, useUser, useWindowDimensions} from '@wollo-lib/kpe-context'
import useTexts from '../lib20/useTexts'
import {useRoot, useConfig,  useFakeUser} from '../lib20/KPE20context'
// import {devMode} from '../config.jsx'
import {ReactComponent as Logo} from '../resources/LilieWhite.svg'
import ChangeLog, {NewVersion} from '../lib20/Changelog/Changelog'
import ConnectionStatus from '../lib20/Menu/ConnectionStatus'
import AvatarMenu from '../lib20/Menu/AvatarMenu'
import MenuHelp from '../lib20/Menu/MenuHelp'

import {version} from '../config'


const MenuItems=(props) => {
    // const history=useHistory()
    const texts=useTexts('Menu')
    const config=useConfig()
    const {apiNextId} = useApi()

    const {extraMenu, isAdmin, bufue, isDesktop, setSidebarVisible, setModalParams, filterParams,type, setFilterParams} = props

    const history = useHistory()




    const setFilterType=(type) => {
        if (type === null) {
            setFilterParams(f=>({...f, type:type, text:''}))
        }
        else {
            setFilterParams(f=>({...f, type:type}))
        }

        // setModalParams({
        //     addLevel:{
        //         component:EditFilter,
        //         type:type,
        //         filterParams:filterParams,
        //         setFilterParams:setFilterParams
        //     }
        // })
    }
    useEffect(()=>{
        /*setModalParams({
            addLevel:{
                component:EditFilter,
                type:type,
                filterParams:filterParams,
                setFilterParams:setFilterParams
            }
        })*/
    },[filterParams])

    const newLP= async(Art) => {
        const ID= await apiNextId('kpe_op','BLagerplatz','lagerplatz')
        
    }
    
    const onClick=(ev,{name}) => {
        history.push(name)
        setSidebarVisible(false)
    }

    const newMenu=
        <Dropdown item simple text={texts.New} key='new'>
            <Dropdown.Menu>
                {
                    Object.entries(config.locationTypes).map(([key,value])=>
                    (
                        <Dropdown.Item
                            onClick={()=>history.push('/edit/new/'+key)}
                            content={value.name}
                        />
                    ))
                }
                
            </Dropdown.Menu>
        </Dropdown>




    const myExtraMenu=
        extraMenu.map((el,Index)=>{

            const MyMenu=el.menu
                if(!el.admin || isAdmin)
                    return(
                        <MyMenu 
                            {...props}
                            {...el.props}
                            isDesktop={isDesktop}
                            setSidebarVisible={setSidebarVisible}
                        />)
                else
                    return ''
        })

   
    

    return [newMenu,  ...myExtraMenu]
}




const MainMenu=(props) => {
    const texts=useTexts('Menu')

    const {apiSelect}=useApi()
    const [bufue,setBufue]=useState(false)
    const user=useUser()
    const config = useConfig()
    const history=useHistory()
    const windowWidth=useWindowDimensions().width
    const {setModalParams}=props  
    const {fakeUser}=useFakeUser() 
    const fakeUserMenuColor = 'orange'
    
    const [sidebarVisible, setSidebarVisible]=useState(false)
    const [changelogVisible, setChangelogVisible] = useState(false)
    const [isDesktop, setDesktop]=useState(true)
    useEffect(()=>{
        windowWidth< 800 ? setDesktop(false) : setDesktop(true)
    }, [windowWidth])

    useEffect(() => {
        // const isBufue= async() => {
        //     const res= await apiSelect('/db/InListe/3089/_userPIndex','base')
        //     let adm = []
        //     if(devMode)
        //         adm= await apiSelect('/db/InListe/5036/_userPIndex','base')
        //     if(res.length || adm.length)
        //         setBufue(true)
        // }
        // isBufue()

    },[])
    
    return (<>
        <ChangeLog visible={changelogVisible} setVisible={setChangelogVisible} />
        <NewVersion/>
        {isDesktop ? 
            <Menu attached color={fakeUser ? fakeUserMenuColor : 'blue'} inverted className='MainMenu' style={{marginBottom:'20px'}} key='MainMenu' simple>
                <Menu.Item
                        key='logo'
                        onClick={el=>{history.push('/')}}>
                    <Logo width={35} height={35}/>
                    <ConnectionStatus key='ConnectionStatus' className='desktop' />
                </Menu.Item>
                <MenuItems setSidebarVisible={setSidebarVisible} bufue={bufue} {...props} isDesktop={false}/>
                <MenuHelp
                    setModalParams={setModalParams}
                    setSidebarVisible={setSidebarVisible}
                />

                <Dropdown as={Menu.Item} position='right' icon={null} item direction='left' key='avatar' trigger={
                    !config || !config.orgaUser || !config.orgaUser.Data || !config.orgaUser.Data.avatar 
                    ? <Icon name='user' circular key='IconUser'/>
                    : <Image src={config.orgaUser.Data.avatar} circular={isDesktop} size='mini' />
                }>
                    <Dropdown.Menu >
                        <Menu.Header style={{textAlign:'center'}}>{config.orgaUser.Data.firstName} {config.orgaUser.Data.lastName}</Menu.Header>
                        <p style={{color:'grey', fontSize:'70%', textAlign:'center'}}>{texts.AppName} v{version}</p>
                        <AvatarMenu {...props} isDesktop={true} windowWidth={windowWidth} setSidebarVisible={setSidebarVisible} setModalParams={setModalParams} setChangelogVisible={setChangelogVisible}/>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu>

        :
        <Menu attached color={fakeUser ? fakeUserMenuColor : 'blue'} inverted className='MainMenu' style={{marginBottom:'20px'}}>
            <Dropdown item icon='bars' onClick={e=>setSidebarVisible(status=>!status)} direction='left' key='MainBars' >
                {/* <ConnectionStatus className='mobile'/> */}
            </Dropdown>
            <Sidebar visible={sidebarVisible} onHide={() => setSidebarVisible(false)} animation='overlay' as={Segment} color='blue' inverted vertical key='SideBar'>
                    <Menu.Item onClick={() => setSidebarVisible(false)} icon='close' header content={user.fullName} key='SideClose'/>
                    <Divider key='SideDiv1'/>
                    <p style={{fontSize:'70%', marginLeft: '20px'}}>{texts.AppName} v{version}</p>
                    <AvatarMenu {...props} isDesktop={false} setSidebarVisible={setSidebarVisible} windowWidth={windowWidth} setModalParams={setModalParams}/>
                    <Divider />
                    <MenuItems setSidebarVisible={setSidebarVisible} bufue={bufue} {...props} isDesktop={false}/>
            </Sidebar>
            
        </Menu>}
    </>)
}
export default MainMenu
