import React, {useEffect, useState} from 'react'
import {Form, Button, Segment, Icon, Dropdown, Flag, Modal} from 'semantic-ui-react'
import {useApi} from '@wollo-lib/kpe-context'   
import useTexts from '../lib20/useTexts'
import countries from '../lib20/countries.json'
import {useConfig} from '../lib20/KPE20context'
import {SearchSelect,useCheckForm} from '@wollo-lib/wollo-react'
import parsePhoneNumber, {AsYouType,isPossiblePhoneNumber,
    isValidPhoneNumber,} from 'libphonenumber-js/max' //https://www.npmjs.com/package/libphonenumber-js


const EditAddress =(props)=> {

  
    const config=useConfig()
    const texts=useTexts('/edit/contact')
    
    const [contactDB,setContactDB]= useState(null)
    const {children, modalParams, resetModal, modalSubmit}=props
    const {contactIndex,contact,setLocation}=modalParams[0]
    const [lang,countryOrga]=config.locale.split('-')

    const intlCompare=new Intl.Collator(lang).compare
    const countryOptions=countries.sort((first,second)=> (intlCompare(first[lang],second[lang]))).map(c=>({key:c.id,value:c.alpha2,text:c[lang], flag:c.alpha2}))

    const [contactObject, setContactObject]=useState({
        name: null,
        road: null,
        housenumber: null,
        city: null,
        postcode: null,
        countrycode: countryOrga.toLowerCase()
    })
    const [phone, setPhone]=useState({number:'',country:countryOrga})




   const {errors,checkSubmit}= useCheckForm(contactObject,
        [

            {name:'name', error: texts.missingName,test:(val)=>(
                val!==null && val!=='')},
            {name:'countryCode', error: texts.invalidCountry,test:(val)=>(
                (!contactObject.city) ||  val!=='')},
            /*{name:'city', error: texts.invalidCity, test:(val)=>{
                return  contactObject.city!==null && contactObject.city!=='' && contactObject.postcode !=='' && contactObject.postcode !==null
            }},*/
            {name:'email', error: texts.invalidEmail,test:(val,index)=>(
                !val || val==='' ||  val.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/))
        },
            
            
        
        ]
    )
    const { errors: phoneErrors, checkSubmit: checkPhone}= useCheckForm(phone,
        [
            {name:'number', error: texts.invalidPhone, test:(val)=>{
                return ( phone.number==='')
                 || ( phone.country &&  val && isValidPhoneNumber(val,phone.country.toUpperCase())
                )
            }},
            {name:'country', error: texts.invalidCounty,test:(val)=>(
               val!=='')
            }

        ]
    )
  
   
    useEffect(()=>{
        if(contactIndex<0)
        {
            // new contact
            setContactObject({
                name: null,
                road: null,
                housenumber: null,
                city: null,
                postcode: null,
                countrycode: countryOrga.toLowerCase(),
                email: null,
            })
            setPhone( {number:'',country:countryOrga.toLowerCase()})
            setContactDB(null)

        }
        else
        {
            
            if( contact.UID)
            {
                setContactDB(contact.UID.toString())
            }
            else
            {
                if(contact.phone)
                    setPhone(parsePhoneNumber(contact.phone))
                setContactObject({...contact})
            }
        }


    },[contactIndex])

   
    modalSubmit.current= async ()=> {
        const checks=[checkSubmit(),checkPhone()]
        if(contactDB || (checks[0] && checks[1]))
        {  
            const myContact= contactDB ? contactDB : {...contactObject,phone:phone && isValidPhoneNumber(phone.number, phone.country.toUpperCase()) ? parsePhoneNumber(phone.number,phone.country.toUpperCase()).formatInternational() : undefined }
            if(contactIndex<0)
            {
                setLocation(l=>({...l,contacts:[...l.contacts,myContact]}))
            }
            else
            {
                setLocation(l=>({...l,contacts:l.contacts.map((c,i)=>(i===contactIndex ? myContact : c))}))
            }   
            resetModal()
        }
    }


 

  
    const handleChange= (ev, {name, value}) => {
        if(name==='city') 
        {

            if(value!==null) {

                const [zip,city]=value.split(':')
                setContactObject((o) =>  ({...o,postcode:zip,city:city}))
            }

            else {

                setContactObject((o) => ({...o,postcode:'',city:''}))                   
            }
        }
        else
        {
            setContactObject((o)=>({...o,[name]:value}))
        }


    }


    const handleChangePhone=(ev,{name,value})=>
    {
        if(name==='number')
        {
            const number= parsePhoneNumber(value,phone.country.toUpperCase())
            const myYouType = new AsYouType(phone.country.toUpperCase())
            setPhone(p=>({...p,number: myYouType.input(value)}))

        }
        setPhone(p=>({...p,[name]:value}))

    }

 




    return(<>

        <Modal.Header as='h3'>{texts.contactHeader}</Modal.Header>
        <Form as={Modal.Content}>

            <Form as={Segment} widths={16}>
                <Modal.Header as='h4' content={texts.externalContact}/>
                    <Form.Field
                                    label={texts.contactDB}
                                    placeholder={texts.contactDB}
                                    control={SearchSelect}
                                    api="kpe20"
                                    SuggestUrl={'/kpe20/SearchData?types=["person","extern","guest","job"]'}
                                    Value2TitleUrl='/kpe20/Value2Title?types=["person","extern","guest","job"]'
                                    name="contactDB"
                                    value={contactDB}
                                    autoSelect
                                    onChange={(ev,{value})=>setContactDB(value)}
                            />
                <Modal.Header as='h4' content={texts.internalContact}/>
                <Form.Input
                        placeholder={texts.name}
                        label={texts.name}
                        value={contactObject.name}
                        width={16}
                        name='name'
                        onChange={handleChange}
                        disabled={contactDB!==null}
                        error={errors['name']}
                />
                <Form.Group >
                    <Form.Input
                        placeholder={texts.road}
                        label={texts.road}
                        value={contactObject.road}
                        width={12}
                        name='road'
                        onChange={handleChange}
                        disabled={contactDB!==null}
                    />
                    
                    <Form.Input
                        placeholder={texts.houseNumber}
                        label={texts.houseNumber}
                        value={contactObject.houseNumber}
                        width={4}
                        name='houseNumber'
                        onChange={handleChange}
                        disabled={contactDB!==null}
                    />
                </Form.Group>
              
                <Form.Group>
                    <Form.Dropdown
                        options={countryOptions}
                        label={texts.countryCode}
                        value={ contactObject.countryCode}
                        width={4}
                        name='countryCode'
                        onChange={handleChange}
                        trigger={
                            <Flag name={contactObject.countrycode}/>
                        }
                        icon=''
                        error={errors['countryCode']}
                        pointing='top left'
                        scrolling
                        button
                        basic
                        compact
                        search
                        disabled={contactDB!==null}
                        key={'countryCode'}

                    />
                    <Form.Field
                        control={SearchSelect}
                        placeholder={texts.city}
                        label={texts.city}
                        value={contactObject.postcode+':'+contactObject.city}
                        width={12}
                        name='city'
                        onChange={handleChange}
                        api='kpe20'
                        SuggestUrl={'/kpe20/SearchData/places/'+contactObject.countrycode}
                        Value2TitleUrl={'/kpe20/Value2Title/places/'+contactObject.countrycode}
                        error={errors['city']}
                        disabled={contactDB!==null}
                        key={'city'}
                    />
                </Form.Group>
                <Modal.Header as='h4' content={texts.telephone}/>
                <Form.Group >
                        <Form.Dropdown
                            options={countryOptions}
                            label={texts.countryCode}
                            value={ phone?.country}
                            width={4}
                            name='country'
                            onChange={handleChangePhone}
                            trigger={
                                <Flag name={phone?.country}/>
                            }
                            icon=''
                            error={phoneErrors['country']}
                            pointing='top left'
                            scrolling
                            button
                            basic
                            compact
                            search
                            disabled={contactDB!==null}
                        />
                        <Form.Input
                            name='number'
                            label={texts.phoneNumber}
                            placeholder={texts.phoneNumber}
                            value={phone?.number}
                            onChange={handleChangePhone}
                            error={phoneErrors['number']}
                            width={12}
                            disabled={contactDB!==null}
                        />
                </Form.Group>
                <Form.Input
                    name='email'
                    label={texts.email}
                    placeholder={texts.email}
                    value={contactObject.email}
                    onChange={handleChange}
                    error={errors['email']}
                    width={16}
                    disabled={contactDB!==null}
                />

            </Form>
   
        </Form>
        {children}
    </>)
}
export default EditAddress