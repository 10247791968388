import React, {useState, useEffect, useRef} from 'react'
import {Segment, Header,Label, Container, Popup, Icon, Image, Grid, Button} from 'semantic-ui-react'
import {showDatum} from '@wollo-lib/wollo-react'
import {useApi} from '@wollo-lib/kpe-context'
import L from 'leaflet'
import {useParams, useHistory} from 'react-router-dom'
import { MapContainer, useMap, useMapEvents, Marker, ScaleControl} from 'react-leaflet'
import  useTexts from '../lib20/useTexts'
import {useConfig} from '../lib20/KPE20context'
import SVGIcon from '../lib/SvgIcon'
import countries from '../resources/countries.json'
import {leafletLayers, LayerSelect, CenterToggleButton, CenterButton} from '../lib/LeafletLayers'
import AddressLink from '../edit/PersonAddressOrRoute'
import  MapLink from './MapLink'
// https://dev.to/jessicabetts/how-to-use-google-maps-api-and-react-js-26c2
// https://github.com/fullstackreact/google-maps-react


import MenuEdit from './MenuEdit.jsx'

export const MapAuto= ({geo, moved, setMoved}) => {
    // keyps the map centerd on scrolling
    const [zoom,setZoom]=useState(12)
    // const [moved,setMoved]= useState(false)
    const zooming=useRef(false)

    const map = useMap();
    useEffect(() => {
        if(!moved)
            map.setView([geo.lat, geo.lng],zoom);
       
    }, [geo,zoom,moved]);

    const mapEvents = useMapEvents({
        zoomend: ()=>{
            
            setZoom(mapEvents.getZoom())
            zooming.current=false
        },
        zoomstart: ()=>{
            zooming.current=true
        },
        movestart: ()=>{
            const newGeo=mapEvents.getCenter()
            if(!newGeo.equals(L.latLng([geo.lat,geo.lng])) && !zooming.current)
            {
                setMoved(true)
            }
        }
        
    
    })
    return null;
}


const Show=(props) => {

    const { setExtraMenu} = props
    const {apiSelect, apiUpdate, apiDelete} = useApi()

    let history = useHistory();
    let {UID}=useParams() // url parameter
    const texts=useTexts('/show')
    const config=useConfig()
    const [location,setLocation] = useState()
    const [contacts, setContacts] = useState([])
    const [criteria,setCriteria]=useState({})
    const [mapLayer,setMapLayer]=useState('Osm')

    useEffect(()=> {
        setExtraMenu({
            action: 'replace',
            menus:[
                {admin:false, menu:MenuEdit, props:{location}}
            ]
        })
    },[location])

    useEffect(()=>{
        const  getContacts=async () =>
        {
            // the contacts can be an object ( external conatcts, not registered in the database) or an UID of a contact in the database
            // fetch now the ones, which are an UID string
            const paras=JSON.stringify( [
                {path:'$.address[0].road',alias:'road'},
                {path:'$.address[0].postcode',alias:'postcode'},
                {path:'$.address[0].houseNumber',alias:'houseNumber'},
                {path:'$.address[0].city',alias:'city'},
                {path:'$.address[0].countryCode',alias:'countryCode'},
                {path:'$.firstName',alias:'firstName'},
                {path:'$.lastName', alias: 'lastName'},
                {path:'$.email[0].email',alias:'email'},
                {path:'$.phone[0].number',alias:'phone'},
            ])
            const fetched= await apiUpdate(`/kpe20/persons/?type=["person","guest","job","extern"]&Data=${paras}`,
            location.contacts.filter(contact=>{
            return   typeof contact==='string'
            }))
            const internalContacts=fetched.result
            .map(c=>({...c,name:`${c.Title} ${c.firstName} ${c.lastName}`}))

            setContacts([...internalContacts,...location.contacts.filter(contact=>(typeof contact!=='string'))])
        }
        if(location &&  location.contacts && location.contacts.length>0)
            getContacts()
    },[location])
    
    useEffect(()=>{     
        async function fetchLocation() 
        {
           const result= await apiSelect('/location/location/'+UID)  
           if(!result.success)
           {
               alert (result.message)
           }
           else if(result.length===0)
           {
               alert(texts.notFound)
               history.push('/')
           }
           else 
                setLocation({...result.result.Data,...result.result,Data:undefined})
        }
        fetchLocation()
    },[UID])

    useEffect(()=>{
            // group location criteria by category
            if(location && location.criteria)
            {
                const cats={}
                const clist=config.locationCriteria
                Object.entries(location.criteria).forEach(([c,value]) => {
                        const cat=clist[c].category
                        if(!cats[cat])
                            cats[cat]={}
                        cats[cat][c]={...clist[c],value:value}
                });
                setCriteria(cats)
            }

    },[location])

    const deleteLocation=async ()=>
    {
        if(window.confirm(texts.confirmDelete))
        {
            const result=await apiDelete('/location/location/'+UID)
            if(result.success)
                history.push('/')
        }
    }

    const MapLayer= leafletLayers[mapLayer]
    

    if(location?.type===undefined)
        return null
    else
        return(
        <div>
          

            <Container >
                <Grid>
                    
                    <Grid.Column width={12}>
                        <Segment>
                            
                        {
                            <Header textAlign='left'>
                                {config.locationTypes[location.type].name} {location.name}
                            {
                            
                                <Header.Subheader >{texts.group} {location.GroupTitle}  {location.GroupDisplay}</Header.Subheader>
                            }
                        
        
                            {
                                !contacts  ? '' :
                                contacts.map(contact=>(
                                    !contact ? '' :
                                        <Popup 
                                            hoverable={true}
                                            trigger={<Header.Subheader >
                                                        {texts.Contact} {contact?.name}&nbsp;
                                                        <a href={`tel:${contact?.phone}`}>{contact?.phone}</a>&nbsp;
                                                        <a href={`mailto:${contact?.email}`}>{contact?.email}</a>
                                                    </Header.Subheader>}
                                            content={
                                            <div> 
                                                
                                                <p><strong>{contact?.name}</strong></p>
                                                <AddressLink address={contact}/>
                                                <p><a href={`tel:${contact.phone}`}><Icon name="phone"/>{contact.phone}</a><br/>
                                                    <a href={`mailto:${contact.email}`}><Icon name="mail"/>{contact.email}</a>

                                                </p>
                                                {/*<p>{contact?.road} {contact?.houseNumber}<br/>
                                                {contact?.postcode} {contact?.city}<br/>
                                                {countries.find(el=>el.alpha2===(contact.countryCode ?? config.CountryCode.toLowerCase()) )[config.CountryCode.toLowerCase()]}</p>
                                                */}
                                            </div>
                                            }
                                        />
                                ))
                            }
                            {
                                location.webUrl===null || location.webUrl==='' ?'':
                                <Header.Subheader><a href={location.webUrl}> {location.webUrl}</a></Header.Subheader>
                            }
                            </Header>

                        }
                        
                            <Button

                                icon='trash'
                                labelPosition='left'
                                basic
                                content={texts.delete}
                                onClick={deleteLocation}
                                width={8}
                                color='red'
                            />
                    
                        </Segment>
                        <Label basic color={location.public ? 'green': 'red'} content={location.public ? texts.public : texts.private} />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Image src= {`/icons/${location.type}.svg`} size='medium' />
                    </Grid.Column>
                </Grid>
                

                {
                    location.description==='' ? '' :
                    <Segment>
                        <div dangerouslySetInnerHTML={{ __html: location.description }} />
                    </Segment>
                }
                {
                    location.geo?.lat===undefined && false ?  ''  :
                    <>
                        <Segment style={{height: 600,padding:0, paddingBottom:56 - 33, marginBottom:40 }}>    
                            <MapContainer
                                
                                zoom={12}
                                style={{position:'absolute', width: '100%',  height: '100%'}}
                                center={[location.geo.lat,location.geo.lng]}
                            >
                                <ScaleControl/>
                                <LayerSelect mapLayer={mapLayer} setMapLayer={setMapLayer} />
                                <CenterButton location={location} geo={location.geo} />

                                <MapLayer/>
                    
                                
                                <Marker
                                
                                    position={[location.geo.lat, location.geo.lng]}
                                    icon={SVGIcon(
                                        {
                                            circleText:config.locationTypes[location.type].short,
                                            color: config.locationTypes[location.type].color[true],
                                        },
                                        
                                    )}
                                            
                    
                                />

                            </MapContainer>
                            <MapLink
                                geo={location.geo}
                                name={location.name}
                                texts={texts}
                                style={{paddingLeft:'150px'}}
                            />
                        </Segment>
                    </>

                }
                

                <Segment as={Grid} container padded stackable style={{paddingTop:'1rem', marginBottom:'20px'}}>

                { 
                    Object.entries(criteria).map(([category,innerC])=>
                    (
                       
                        <>

                            <Grid.Column textAlign='center' key={category} width={16} style={{paddingBottom:'0rem'}}>
                                <Header as='h3' color='blue'>
                                    {category}
                                </Header>
                            </Grid.Column>
                            {
                                Object.entries(innerC).map(([key,el])=>(
                                    <Grid.Column width={4} key={key} style={{'paddingTop':'1rem','paddingBottom':'0rem'}}>
                                
                                    <Segment padded>
                                        <Label attached='top'>{el.name}</Label>
                                        <Grid>
                                        
                                                

                                            {
                                                el.parameterType==='boolean' ?
                                                    <Grid.Column width={16} key={'val'+key} style={{'paddingTop':'0rem','paddingBottom':'0rem'}}>
                                                        {el.value===false ? <Icon color='red' name='toggle off'/>  :   <Icon color='green' name='toggle on'/> }
                                                    </Grid.Column>
                                                :
                                                el.parameterType==='numeric' ?
                                                                                   
                                                    <Grid.Column width={16} key={'val'+key} style={{'paddingTop':'0rem','paddingBottom':'0rem'}}>
                                                        {el.value?.
                                                            toLocaleString(config.locale,{minimumFractionDigits:el.digits})
                                                        }
                                                    </Grid.Column>
                                                :
                                                el.parameterType==='currency' ?
                                                                                   
                                                    <Grid.Column width={16} key={'val'+key} style={{'paddingTop':'0rem','paddingBottom':'0rem'}}>
                                                        {el.value?.
                                                            toLocaleString(config.locale,{minimumFractionDigits:2,currency:el.currency,currencyShow:'sign'})
                                                        }
                                                    </Grid.Column>
                                                 :
                                                                                                                                    
                                                 <Grid.Column width={16} key={'val'+key} style={{'paddingTop':'0rem','paddingBottom':'0rem'}}>
                                                    {el.value}
                                                 </Grid.Column>
                                                
                                            }
                                        </Grid>
                                    </Segment>

                                </Grid.Column>

                                ))
                            }
                        </>
                        
                    ))
                }
                </Segment>
                     



            </Container>

        </div>
        

    )
    
}

export default Show

