import React,{ useState, useEffect, useRef }  from 'react'
import {useApi} from "@wollo-lib/kpe-context"
import { MapContainer,useMap, useMapEvents, ScaleControl} from 'react-leaflet'
import {Segment,Input, Grid, Button, Label} from 'semantic-ui-react'
import LocationMarker from './LocationMarker.jsx'
import { useConfig } from '../lib20/KPE20context.jsx'
import { leafletLayers, LayerSelect} from '../lib/LeafletLayers'
import L from 'leaflet'

const MapAuto= ({zoom,geo,onChange,setZoom}) => {

    const [moved,setMoved]= useState(false)
    const zooming=useRef(false)
    const {apiSelect}=useApi()

    const map = useMap();
     useEffect(() => {
        if(!moved)
            map.setView([geo.lat, geo.lng],zoom);
     }, [geo,zoom]);

    const mapEvents = useMapEvents(
    {
        click: async (e)=>{
            const position= {lat:e.latlng.lat,lng:e.latlng.lng}

            const place=await apiSelect(`/location/geo/${position.lat}/${position.lng}`)
            if(place.success)
            {
                setZoom(aZoom=>Math.max(12,aZoom));
                onChange(e,
                        {
                            geo:position,
                            place:place.result,
                            placeQuery:`${place.result}ll=${position.lat},${position.lng}`,
                        })   
            }
        },
        zoomend: ()=>{
            setZoom(mapEvents.getZoom())
            zooming.current=false
        },
        zoomstart: ()=>{
            zooming.current=true
        },
        movestart: ()=>{
            const newGeo=mapEvents.getCenter()
            if(!newGeo.equals(L.latLng([geo.lat,geo.lng])) && !zooming.current)
            {
                setMoved(true)
            }
        }
   })
   return null
}

const MapsAdresseInput=(props)=>
{
    const {location, onChange, error, name}=props
    const [place,setPlace]=useState('')
    const config=useConfig()
    const [mapLayer,setMapLayer]=useState('Osm')
    const [zoom,setZoom]=useState(5)
    const {apiSelect}=useApi()

    useEffect(()=>{
        if(location && location.place)
            setPlace(location.place.toString())
    },[location])
    
    const changePlaceInput=(ev,{value})=>{
        setPlace(value)
        if(onChange)
            onChange(ev,{value,name})
    }

   

   
  
   
    const changePlace=async ()=>
    {
        const newPlace= await apiSelect(`/location/geo/${place}`)
        if(newPlace.success)
        {
            const geo={
                lat:newPlace.result.location.lat,
                lng:newPlace.result.location.lng,
            }
            setZoom(Math.max(12,zoom))
            const place= newPlace.result.formatted_address
            onChange({},{
                        geo,
                        place,
                        placeQuery:`${place}ll=${geo.lat},${geo.lng}`
                    })

       }
          
            
        
    }
    const onMove=(ev)=>
    {
        console.log(ev)
    }

    const MapLayer= leafletLayers[mapLayer]
    
    return(
    <div>
    {
         
        <div>  
            <Grid>
            <Grid.Column width={12} key={2}>
                <Input 
                        placeholder='Grobe Adresse zur Navigation eingeben' 
                        value={place} 
                        onChange={changePlaceInput} 
                        error={props.error}
                />
            </Grid.Column>
            <Grid.Column width={4} key={3}>
                <Button onClick={changePlace} content='Setze Adresse'/>
            </Grid.Column>
            </Grid>
            <Segment style={{height: props.height,padding:0 }}>
                <MapContainer
                    zoom={zoom}
                    style={{ width: '100%',  height: '100%',}}
                    center={location?.geo?.lat ? [location.geo.lat,location.geo.lng]  :config.defaultMapCenter}
                >
                    <ScaleControl/>

                    <LayerSelect mapLayer={mapLayer} setMapLayer={setMapLayer} />

                    <MapLayer/>
                        {!location?.geo?.lat ? '' :
                            < LocationMarker
                                location={location}
                                config={config}
                            />
                        }
                    <MapAuto
                        geo={location?.geo?.lat ? location.geo : config.defaultMapCenter}
                        zoom={zoom}
                        setZoom={setZoom}
                        onChange={onChange}
                        mapsApiKey={config.mapsApiKey}
                    />
                </MapContainer>
            </Segment>
        </div>
    }
    {
        error!=='' ?? <Label basic color='red' pointing content={error}/>
    }
    </div>

    )
}


export default MapsAdresseInput
