import React,{ useState, useEffect }  from 'react';
import {Header,List, Popup, Button, Label, Icon,Segment, Form} from 'semantic-ui-react'
import ModalContact from './ModalContact'
import countries from '../lib20/countries.json'
import { useConfig } from '../lib20/KPE20context';
import useTexts from '../lib20/useTexts';
import AddressLink from './PersonAddressOrRoute';

const Contacts= ({contacts,location,setLocation,error,setModalParams})=>
{
    const config=useConfig()
    const texts=useTexts('/edit')
    const deleteContact= (ev,{index})=>
    {
        if(window.confirm(texts.confirmDeleteContact+contacts[index].name))
        {
            setLocation(l=>({...l,contacts:l.contacts.filter((c,I)=>I!==index)}))
        }
    }

    return(<Segment secondary>

        <Header as='h4' content={texts.contactsHeader}/>
        <List bulleted size='large'>
        {
            contacts.map((contact,Index)=>(
            
                <Popup 
                    hoverable={true}
                    trigger={<List.Item>
                                
                                <List.Header>
                                    <>
                                        {texts.Contact} {contact.name}&nbsp;
                                        <Button 
                                            size='mini'
                                            icon='edit' color='blue'
                                            onClick={()=> setModalParams({
                                                addLevel:
                                                {
                                                    component: ModalContact,
                                                    setLocation,
                                                    contact,
                                                    contactIndex: Index
                                                }
                                            })} 

                                        />
                                        <Button 
                                            size='mini'
                                            icon='trash' color='red'
                                            onClick={deleteContact} 
                                            index={Index}

                                        />
                                    </>
                                
                                </List.Header>
                                
                            </List.Item>}

                    content={
                        <div>    
                            <p><strong>{contact.name}</strong></p>
                            <AddressLink address={contact}/>
                            <p><a href={`tel:${contact.phone}`}><Icon name="phone"/>{contact.phone}</a><br/>
                                                    <a href={`mailto:${contact.email}`}><Icon name="mail"/>{contact.email}</a>

                            </p>
                            
                        </div>
            }
        />
        ))
}
    </List>
    <Form.Button 
        icon='plus'
        content={texts.addContact}
        onClick={()=> setModalParams({
            addLevel:
            {
                component: ModalContact,
                setLocation,
                contact:{},
                contactIndex: -1
            }
        })} 
    />
    {
       error && error!==''  ? 
            <Label basic pointing color='red' content={error}/> 
            :''
    }

    </Segment>)
}

export default Contacts