import React, {useState, useEffect}  from 'react'
import {Marker,Popup} from 'react-leaflet'
import {showDatum} from '@wollo-lib/wollo-react'
import SVGIcon from '../lib/SvgIcon'
import L from 'leaflet'
import MarkerPopup from './MarkerPopup'


const LocationMarker=(props)=>
{

    const { location,config,history, texts}= props 

    function onClick(ev)
    {
        history.push('/'+location.UID)
    } 
 
    if(location.UID==='UUID-87dd4c43-f883-11ed-832e-0242c0a8d002')
        debugger

        
    return(
        <Marker
            key={location.UID} 
            position={[location.lat,  location.lng]}
            eventHandlers={{
                mouseover: (location) => location.target.openPopup(),
                click: (ev) => onClick()
            }}
            icon={SVGIcon(
                {
                    circleText: config.locationTypes[location.locationType].short,
                    fillColor: config.locationTypes[location.locationType].color[location.locationPublic==="1"],
                    circleColor: config.locationTypes[location.locationType].color[location.locationPublic==="1"],
                    color: location.locationPublic ? 'rgb(100,100,100)' : config.locationTypes[location.locationType].color[false],
                    "iconSize": L.point(25,36),
                },
                
            )}
        >
        <MarkerPopup
            onClick={onClick}
            {...props}
        />


        </Marker>
    )

}



export default LocationMarker
