import React, { useEffect, useState } from 'react'
import {TileLayer, useMap, useMapEvents} from 'react-leaflet'
import L from 'leaflet'
import {Button, Dropdown, Popup} from 'semantic-ui-react'
import useTexts from '../lib20/useTexts'

import {useRef} from 'react'

export const OsmLayer=()=>
{
    return <TileLayer
        url='https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png'
        maxZoom={18}
        attribution= '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    
    />
}

export const TopoLayer=()=>
{
    return <TileLayer
        url='https://opentopomap-proxy.maphub.net/{z}/{x}/{y}.png'         // using a cached version of this map           
        //url='https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png'
        maxZoom= {17}
        attribution= 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
    />
}

export const SatLayer=()=>
{
    return <TileLayer
        url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
        attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'   
    />
}

export const OPNVLayer=()=>
{
    return <TileLayer
            url='https://tileserver.memomaps.de/tilegen/{z}/{x}/{y}.png'
	        maxZoom={18}
	        attribution= 'Map <a href="https://memomaps.de/">memomaps.de</a> <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    />
}

export const leafletLayers=
{
    Osm:OsmLayer,
    Topo:TopoLayer,
    Sat:SatLayer,
    OPNV:OPNVLayer

}


export const CenterButton = (props) => {
    const {geo} = props
    const texts = useTexts('Map')

    const [centered, setCentered] = useState(true)
    const [moved,setMoved]= useState(false)

    // keeps the map centerd on scrolling
    const [zoom,setZoom]=useState(12)
    const zooming=useRef(false)

    const map = useMap()
    
    useEffect(() => {
        if(!moved)
            map.setView([geo.lat, geo.lng],zoom);
       
    }, [geo,zoom,moved]);


    const center = () => {
        map.setView([geo.lat,geo.lng], map.getZoom())
        setMoved(false)
        setCentered(true)
    }

    


    const mapEvents = useMapEvents({
        zoomend: ()=>{
            
            setZoom(mapEvents.getZoom())
            zooming.current=false
        },
        zoomstart: ()=>{
            zooming.current=true
        },
        movestart: ()=>{
            const newGeo=mapEvents.getCenter()
            if(!newGeo.equals(L.latLng([geo.lat,geo.lng])) && !zooming.current)
            {
                setMoved(true)
            }
        },
        dragend: () => {
            setCentered(geo === map.getCenter())
        }
        
    })

    return (
        <div style={{position:'relative', marginTop:'40px', marginLeft:'10px'}} >
            <Popup 
                content={texts.center}
                position='bottom left'
                wide={false}
                trigger={
                    <Button 
                        circular 
                        icon='map marker alternate'
                        floated='left' 
                        size='medium' 
                        style={{border:'2px solid rgba(0,0,0,0.2)', background:'white', color:'black'}}
                        onClick={center}
                        disabled={centered}
                    />
                }
            />
        </div>
    )
}

export const LayerSelect = (props) => {
    const {mapLayer, setMapLayer}=props

    const texts = useTexts('Map')

    return (<>
        <div style={{position:'relative', textAlign:'right', marginTop:'10px', marginRight:'10px'}} >
            <Dropdown 
                simple basic
                icon=''
                trigger={
                    <Button 
                        circular 
                        icon='map' 
                        floated='right'
                        size='medium'
                        style={{border:'2px solid rgba(0,0,0,0.2)', background:'white', color:'black'}}
                    />
                }
            >
                <Dropdown.Menu style={{position:'relative',marginTop:'-15px'}} >
                    <Dropdown.Header>{texts.mapTypes}</Dropdown.Header>
                    {Object.keys(leafletLayers).map(key=>
                        <Dropdown.Item
                            size='mini'
                            content={texts[key]}
                            onClick={()=>setMapLayer(key)}
                            toggle 
                            active={mapLayer===key}
                            key={key}
                        />
                    )}
                </Dropdown.Menu>
            </Dropdown>
            
        </div>
    </>)
} 