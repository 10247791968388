import React, {useState, useEffect}  from 'react'
import {Dropdown,Menu,Icon} from 'semantic-ui-react'
import useTexts from '../lib20/useTexts'


const FilterMenu = (props)=>
{
    const {filter,setFilter,types,setTypes, modalParams,setModalParams}=props
    const texts=useTexts('Menu')

    const clickHandler=async (ev,{name,size,dimmer,closeicon})=>
    {
        const module=await import('./'+name)
        setModalParams({
            addLevel:{
                component:module.default,
                size: size,
                closeIcon:closeicon,
                dimmer:dimmer,
                filter,
                setFilter,
                types,
                setTypes
            }
        })
    }

    useEffect(()=>{
        if(modalParams.length>0)
        {
            setModalParams({modify:{filter,types, setTypes,setFilter}})
        }
    },[types,filter,setFilter,setTypes ])


    return(
        <Dropdown item simple key='filter' text={texts.filter} icon='filter'>
         
            
            <Dropdown.Menu>

                <Dropdown.Item onClick={clickHandler} size='large' closeicon name='EditFilter' key='personFamily'>
                        {texts.editFilter}
                </Dropdown.Item>                           

            
                <Dropdown.Item
                    onClick={()=>setFilter([])}
                    content={texts.showAll}
                />

            </Dropdown.Menu>
        </Dropdown>
    )
}

export default FilterMenu