import React, { useState, useEffect, useRef } from 'react'
import { useApi } from '@wollo-lib/kpe-context'
import {Button, Container, Grid,  Form, Dropdown, Message, List, Image, Segment} from 'semantic-ui-react'
import { useHistory, useParams } from 'react-router-dom';
import { SearchSelect, useCheckForm } from '@wollo-lib/wollo-react'
//import {editor as CKEditorClassic} from '@wollo-lib/wollo-ckeditor'
import CKEditorClassic from '../lib20/CKeditor/CKeditor'
import MapsAdresseInput from './MapsAdresseInput'
import EditCategories from './EditCategories'
import Contacts from './Contacts'
import useQuery from '../Hooks/useQuery'
import { useConfig } from '../lib20/KPE20context'
import useTexts from '../lib20/useTexts.jsx';


import campsite from '../resources/icons/campsite.png'
import house from '../resources/icons/house.png'

const Edit = (props) => {
    const { apiSelect, apiInsert, apiUpdate } = useApi()
    let history = useHistory();
    const { UID, type} = useParams()
    const [location, setLocation] = useState({})
    const [criteria, setCriteria] = useState([])
    const [owner, setOwner] = useState({})
    const [group, setGroup] = useState({})
    const [contacts, setContacts] =useState([])
    const isNew = useRef()
    const query = useQuery()
    const config = useConfig()
    const texts = useTexts('/edit')
    const {setModalParams, isAdmin}=props
    const [groupOptions, setGroupOptions]=useState([])
    // loading 


     // retrieve the groups the user has a job in
    // if no job found, deny adding List
    useEffect(()=>{
        const getJobs=async ()=>
        {
            const resJobs=await apiSelect(`/kpe20/persons/${config.orgaUser.UID}?type=job&siblings=true`,'kpe20')
            if(resJobs.success)
            {
          
                setGroupOptions(resJobs.result
                    .reduce(
                        (newOptions,actOption)=>
                        (
                            newOptions.some(job=>actOption.UIDgroup===job.UIDgroup)? newOptions : [...newOptions,actOption] ),
                        [])
                    .map(job => ({value:job.UIDgroup,key:job.UIDgroup,text:job.pGroup})))
            }
        }
        getJobs()
    },[])
    

    useEffect(() => {
        async function fetchLocation() {
            const result = await apiSelect('/location/location/' + UID)
            setLocation(result.result.Data)
            setOwner({
                Title: result.result.OwnerTitle,
                Display: result.result.OwnerDisplay,
                UID: result.result.UIDowner
            })
            setGroup({
                Title: result.result.GroupTitle,
                Display: result.result.GroupDisplay,
                UID: result.result.UIDgroup
            })
        }
        if (UID !== 'new')
            fetchLocation()
        else {
            // new 
            setLocation({

                "name": "",
                "contacts": [],
                "type": type,
                "place": "",
                "placeQuerye": "",
                "geo": config.mapCenter,
                "description": "",
                "url": "",
                "public": true,
                "type": type,
                criteria:[]
            })
            isNew.current = true

        }
    }, [UID])

    useEffect(() => {
        setLocation(loc => ({
            ...loc,
            type:type
        }))
    },[type])



    // mapping criteria
    useEffect(() => {

        if (location?.criteria) {
            const cats = {}
            const clist = config.locationCriteria
            Object.entries(clist).filter(([key, c]) => c.types.includes(location.type)).forEach(([key, c]) => {
                const cat = c.category
                if (!cats[cat])
                    cats[cat] = {}
                cats[cat][key] = { ...c, value: location.criteria[key] }
            });
            setCriteria(cats)
        }

    }, [location])


    useEffect(()=>{
        const  getContacts=async () =>
        {
            // the contacts can be an object ( external conatcts, not registered in the database) or an UID of a contact in the database
            // fetch now the ones, which are an UID string
            const paras=JSON.stringify( [
                                            {path:'$.address[0].road',alias:'road'},
                                            {path:'$.address[0].postcode',alias:'postcode'},
                                            {path:'$.address[0].houseNumber',alias:'houseNumber'},
                                            {path:'$.address[0].city',alias:'city'},
                                            {path:'$.address[0].countryCode',alias:'countryCode'},
                                            {path:'$.firstName',alias:'firstName'},
                                            {path:'$.lastName', alias: 'lastName'},
                                            {path:'$.email[0].email',alias:'email'},
                                            {path:'$.phone[0].number',alias:'phone'},
                                        ])
            const fetched= await apiUpdate(`/kpe20/persons/?type=["person","guest","job","extern"]&Data=${paras}`,
                location.contacts.filter(contact=>{
                    return   typeof contact==='string'
                }))
            const internalContacts=fetched.result
                .map(c=>({...c,name:`${c.Title} ${c.firstName} ${c.lastName}`}))

            setContacts([...internalContacts,...location.contacts.filter(contact=>(typeof contact!=='string'))])
        }
        if(location && location.contacts)
            getContacts()
    },[location])
    


    // Form error Handling 

    const { errors, checkSubmit, hasError} = useCheckForm(location,
        [
            { name: 'name', error: texts.missingName, test: ((val) => (val !== '' && val !==null)) },
        
            { name: 'public', error: texts.missingShare, test: ((val) => 
                {
                    return val!==undefined && val!==null
                })
            },
            { name: 'contacts', error: texts.missingContacts, test: (val) =>
                {
                     return (!location.public|| (val && val.length>0))
                } },
            { name: 'description', error: texts.missingDescription, test: (val) =>
            {
                    return (!location.public|| (val && val!=='' ))
            } },
            { name: 'geo', error: texts.missingPosition, test: (val) => (val && val.lat  && val.lng ) }
        ])

    const { errors: groupErrors, checkSubmit: checkSubmit2, hasError: hasGroupError} = useCheckForm(group,
            [
                { name: 'UID', error: texts.missingGroup , test: (val) => (val) }
            ]

    )
    const handleLocationChange = (e, { name, value }) => {
        setLocation({ ...location, [name]: value })
    }

    const handleGroupChange = (e, { name, value }) => {
        setGroup({UID:value})
    }

    const handleOwnerChange = (e, { name, value }) => {
        setOwner(value)
    }

    const handlePosChange = (e, { geo, place, placeQuery, name }) => {
        setLocation(old=>({ ...old, geo:geo, placeQuery: placeQuery, place: place }))
    }


    const submit = async () => {

        const checks=[checkSubmit(),checkSubmit2()]
        if (checks[0] && checks[1])
        {
            // update or insert location data in database
            const myCriteria={} 
            Object.values(criteria).forEach( category=>
                {
                    Object.entries(category).forEach(([key,crit])=>
                    {
                        if(crit.value)
                            myCriteria[key]=crit.value

                    })
                }

            )
            const myData={

                ...location,
                criteria: myCriteria,
                contacts: contacts,
            }
            if(UID!=='new')
                myData.UID=UID
            const result= await apiInsert('/location/location/'+group.UID,myData )
            if(result.success)
            {
                // if callback supplied by other app (e.g. events), go to this one
                if (query.get('cb'))
                    window.location.href = decodeURIComponent(query.get('cb') +result.result.UID)
                else
                    history.push('/' +result.result.UID)   
            }
        }  
    }
    return (
        <div>


            <Container text>
                <Form error={(Object.keys(errors).length>0 || Object.keys(groupErrors).length>0)}>
                    <Segment>
                        <Grid style={{marginBottom:'1px'}}>
                            <Grid.Column width={10}>
                                    <Form.Input
                                        label={texts.name}
                                        value={location.name}
                                        name='name'
                                        onChange={handleLocationChange}
                                        placeholder={texts.namePlaceholder}
                                        error={errors['name']}
                                        width={13}
                                        // style={{minWidth:'330px'}}
                                    />
                                    {
                                        isAdmin ?
                                        <Form.Field
                                            label={texts.ownerGroup}
                                            placeholder={texts.searchGroup}
                                            control={SearchSelect}
                                            api="kpe20"
                                            SuggestUrl={'/kpe20/SearchData?types=group&changeable=true'}
                                            Value2TitleUrl='/kpe20/Value2Title?types=group'
                                            name="UIDgroup"
                                            value={group?.UID}
                                            autoSelect
                                            onChange={handleGroupChange}
                                            error={groupErrors.UID}
                                            width={13}
                                            // style={{minWidth:'330px'}}
                                        />
                                        :
                                        <Form.Select
                                            label = {texts.ownerGroup}
                                            placeholder = {texts.searchGroup}
                                            api = "kpe20"
                                            name = "group"
                                            value = {group?.UID}
                                            options= {groupOptions}
                                            onChange={handleGroupChange}
                                            error={groupErrors.UID}
                                            // style={{minWidth:'330px'}}
                                        />
                                    }
                                    <Form.Field

                                        label={texts.visibility}
                                        placeholder={texts.visPlaceholder}
                                        control={Dropdown}
                                        value={location.public}
                                        name='public'
                                        error={errors['Public']}
                                        onChange={handleLocationChange}
                                        selection
                                        options={[
                                            { key: 1, text: texts.public?.public, value: true },
                                            { key: 0, text: texts.public?.group, value: false}
                                        ]}
                                        width={6}
                                        // style={{width:'330px'}}
                                    />
                            
                                
                                    <Form.Field
                                        control={Contacts}
                                        contacts={contacts}
                                        location={location}
                                        setLocation={setLocation}
                                        error={errors.contacts}
                                        setModalParams={setModalParams}
                                        // style={{width:'330px'}}
                                    />
                            
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <div>
                                    <Image src={`/icons/${location.type}.svg`} size='medium' />
                                </div>
                            </Grid.Column>
                        </Grid>
                                    
                        
                        <Form.Input label={texts.urlLabel}
                            value={location.webUrl} 
                            name='webUrl' 
                            onChange={handleLocationChange} 
                            placeholder={texts.urlPlaceholder} />
                        <Form.Field
                            label={texts.descriptionLabel}
                            control={CKEditorClassic}
                            value={location.description}
                            onChange={handleLocationChange}
                            name='description'
                            error={errors.description}
                        />
                    </Segment>
                    <Segment>
                        <Form.Field
                            label={texts.mapLabel}
                            control={MapsAdresseInput}
                            location={location}
                            height={600}
                            onChange={handlePosChange}
                            error={errors['geo']} required
                        />
                    </Segment>
                    <Segment>
                        <EditCategories
                            criteria={criteria}
                            setCriteria={setCriteria}
                            config={config}
                            type={location.type}

                        />
                    </Segment>
                   
                    
                    <Message header={texts.stillErrors} error 
                        content={
                            <List bulleted>
                                {
                                    [
                                        ...Object.values(errors),
                                        ...Object.values(groupErrors),
                                    ].map((error)=><List.Item>{error}</List.Item>)
                                }
                            </List>
                        }
                    />

                    <Button.Group fluid>
                        <Button
                            positive
                            icon='save'
                            content={texts.save}
                            onClick={submit}
                            disabled={Object.keys(errors).length>0 || Object.keys(groupErrors).length>0}
                        />
                        <Button
                            negative
                            onClick={e => { history.push('/' + UID) }}
                            icon='cancel'
                            content={texts.cancel}
                        />
                   </Button.Group>
                </Form>

            </Container>

        </div>
    )

}

export default Edit
