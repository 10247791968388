import React, {useEffect, useReducer, useState} from 'react'
import {BrowserRouter as Router, Switch, Route, useParams, useLocation} from 'react-router-dom'

import LocationsMap from './MainMap/LocationsMap.jsx'
import Show from './show/Show'
import Edit from './edit/Edit'
import MainMenu from './MainMenu/MainMenu.jsx'

import {useConfig} from './lib20/KPE20context.jsx'
import {useApi} from '@wollo-lib/kpe-context'
import CentralModal from './lib20/CentralModal.jsx'
import Settings         from  './lib20/Settings/Settings'
import FakeUser         from  './lib20/FakeUser'
import { app, devMode, basePath } from './config.jsx'
import './resources/leaflet.css'
import useTexts from './lib20/useTexts.jsx'

const App = () => {
    const config = useConfig()
    const {apiSelect,apiPutFile} = useApi()

    const texts = useTexts('App')

    const reducerModalParams=(state,action)=> {
        const entries=Object.entries(action)
        const [actionKey,params]=entries[0]
        switch(actionKey) {
            case 'addLevel':
                return [params,...state]

            case 'close':
                return state.filter((el,Index)=>Index!==0)

            case 'setAll':
                return params

            // if you need to update state in a modal, you can use these ones
            case 'replace':
                return state.map((el,Index)=>((action.level && Index===action.level)||(!action.level && Index===0) ? params : el))

            case 'modify':
                if(state) {
                    const newState=state.map(level => {
                        const modifier={}
                        // make sure, that we are only modifying parameters, which are already preasent in the state
                        Object.keys(params).forEach((p=>{if(level[p]) modifier[p]=params[p]}))
                        return {...level,...modifier}
                    })
                    return newState
                }
                else
                    return null   
            default:
                console.error('invalid setModalParams key', actionKey)
        }
    }

    const reducerExtraMenu=(state,action)=>
    {
        switch(action.action)
        {
            case 'add': 
                const result= Array(Math.max(action.position+1,state.length)).fill(null).map((m,Index)=>
                (
                        Index===action.position ? action.menu: state[Index] 
                ))
                return result
                break
            case 'replace':
                return action.menus
            default:
                return state
        }
    }

    const [extraMenu, setExtraMenu]             = useReducer(reducerExtraMenu,[])   // array of components for main menu addOns
    const [isAdmin, setAdmin]                   = useState(false)                   // store here, if user is admin
    const [isBaseAdmin, setBaseAdmin]           = useState(false)                   // store here, if basic user is an admin

    const [modalParams, setModalParams]         = useReducer(reducerModalParams,[]) // modal dialog stack
    const [theme, setTheme]                     = useState('light')


    useEffect(()=>{
        document.title=(devMode ? 'Test ' : '') + texts.Title
    },[texts])

    useEffect(() => {
        if (!config.orgaUser.Data.settings?.theme) {
            setTheme('light')
            return
        }
        
        if (config.orgaUser.Data.settings.theme === 'system') {
            setTheme(window.matchMedia("(prefers-color-scheme: dark)").matches ? 'dark' : 'light')

        }

        if (theme === 'dark' || config.orgaUser.Data.settings.theme === 'dark') {
            import('./lib20/themes/darkmode.css')
            import('tabulator-tables/dist/css/tabulator_midnight.min.css')
        }
        else {
            import('./lib20/themes/lightmode.css')
            import('tabulator-tables/dist/css/tabulator_semanticui.min.css')
        }
    },[config.orgaUser.Data])

    useEffect( () =>
    {
        const getAdmin=async ()=>
        {
            const res= await apiSelect('/kpe20/isAdmin','kpe20')
            setAdmin(res.result)
            setBaseAdmin(res.resultBase)
            if(res.resultBase)
            {
                // update the actual languageMaster
                const file = new Blob([JSON.stringify(texts,0,4)], {type:'application/json'});
                const result=await apiPutFile(`/kpe20/languages/${app}`,file,'languageMaster.json','') 
            }
        }
        getAdmin()
    },[config])



    // useEffect(() => {
    //     if (!config.orgaUser.Data.settings?.theme) {
    //         setTheme('light')
    //         return
    //     }
        
    //     if (config.orgaUser.Data.settings.theme === 'system') {
    //         setTheme(window.matchMedia("(prefers-color-scheme: dark)").matches ? 'dark' : 'light')

    //     }

    //     if (theme === 'dark' || config.orgaUser.Data.settings.theme === 'dark') {
    //         import('./css/themes/darkmode.css')
    //         import('tabulator-tables/dist/css/tabulator_midnight.min.css')
    //     }
    //     else {
    //         import('./css/themes/lightmode.css')
    //         import('tabulator-tables/dist/css/tabulator_semanticui.min.css')
    //     }
    // },[config.orgaUser.Data])

    return (
        <Router basename={basePath}>
            <MainMenu 
                key='TopMainMenu'
                modalParams={modalParams}
                setModalParams={setModalParams}
                isAdmin={isAdmin}
                isBaseAdmin={isBaseAdmin}
                extraMenu={extraMenu}
            />
            {
                !config.maintenance || isAdmin  ?
                <>
                    <CentralModal 
                        modalParams={modalParams}
                        setModalParams={setModalParams}
                    />
                    


                    <Switch>
                        <Route exact path="/">
                            <LocationsMap
                                setExtraMenu={setExtraMenu}
                                setModalParams={setModalParams}
                            />
                        </Route> 
                        <Route path="/edit/:UID/:type" >
                            <Edit 
                                setExtraMenu={setExtraMenu} 
                                setModalParams={setModalParams}
                                isAdmin={isAdmin}
                            />
                        </Route>          
                        
                        <Route path="/edit/:UID" >
                            <Edit 
                                setExtraMenu={setExtraMenu} 
                                setModalParams={setModalParams}
                                isAdmin={isAdmin}
                            />
                        </Route>
                        <Route exact path="/settings" key={'RouteAdmin'} >
                            <Settings 
                                isAdmin={isAdmin}
                            />
                        
                        </Route>

                        <Route exact path="/:UID" >
                            <Show 
                                setExtraMenu={setExtraMenu}
                            />  
                        </Route>
                        
            
                
                                    
                    </Switch>
                </>
                :''
            }
        </Router>
    )
}

export default App