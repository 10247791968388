import React,{ useState, useEffect }  from 'react'

import { Segment,Input, Grid, Checkbox, Label, Header} from 'semantic-ui-react'
import {showDatum} from '@wollo-lib/wollo-react'
import useTexts from '../lib20/useTexts'


const EditCategories=(props)=>
{
    const texts = useTexts('/edit/categories')

    const {criteria, setCriteria, config, type}=props


    class NumberParser {
        constructor(locale) {
          const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
          const numerals = [...new Intl.NumberFormat(locale, {useGrouping: false}).format(9876543210)].reverse();
          const index = new Map(numerals.map((d, i) => [d, i]));
          this._group = new RegExp(`[${parts.find(d => d.type === "group").value}]`, "g");
          this._decimal = new RegExp(`[${parts.find(d => d.type === "decimal").value}]`);
          this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
          this._index = d => index.get(d);
        }
        parse(string) {
          return (string = string.trim()
            .replace(this._group, "")
            .replace(this._decimal, ".")
            .replace(this._numeral, this._index)) ? +string : NaN;
        }
    }

    const handleChange=(e,{ name,category, value, checked })=>
    {
        let val
        const crit=criteria[category][name]
        if(crit.parameterType=== 'boolean')
            val=value===undefined?checked:value
        else
            val=value
        //console.log(name,value,props.criteria[index])
        setCriteria(cr=>({...cr,[category]:{...cr[category],[name]:{...cr[category][name],value:val,input:true}}}))
        
    }

    const handleCheck=(e,{ name,category, value })=>
    {
        
        const crit=criteria[category][name]
        delete crit.input
        if(['numeric','currency'].includes(criteria[category][name].parameterType))
        {
            const parser=new NumberParser(config.locale)
            const val=parseFloat(parser.parse(value))
            setCriteria(cr=>({...cr,[category]:{...cr[category],[name]:{...cr[category][name],value:val,input:true}}}))
        }
    }

     return (
         
  
            <Grid container padded stackable>

            {    
                Object.entries(criteria).map(([key,category])=>
                (<>
                    <Grid.Column textAlign='center' key={key} width={16} style={{'paddingTop':'1rem','paddingBottom':'0rem'}}>
                        <Header key={'h'+key} as='h3' color='blue'>
                            {key}
                        </Header>
                    </Grid.Column>
                    {
                        Object.entries(category).filter(([ckey,crit])=>crit.types.includes(type)).map(([ckey,crit])=>
                        (
                            <Grid.Column width={4} key={ckey} style={{'paddingTop':'1rem','paddingBottom':'0rem'}}>
                                <Segment padded>
                                    <Label attached='top' key={'l'+key}>{crit.name} </Label>
                                    <Grid key={'g'+ckey}>
                                        {
                                            crit.parameterType==='boolean' ?
                                                <Grid.Column width={16} key={`VAL${ckey}`} style={{'paddingTop':'0rem','paddingBottom':'0rem'}}>
                                                    <Checkbox
                                                            toggle
                                                            name={ckey}
                                                            category={key}
                                                            onChange={handleChange} 
                                                            checked={crit.value}
                                                            key={ckey}                          
                                                    />
                                                </Grid.Column>
                                            :
                                                (crit.parameterType==='alpha'|| crit.input) ?  
                                                    
                                                <Grid.Column width={16} key={`VAL${ckey}`} style={{'padding':'0'}}>
                                            
                                                    <Input 
                                                        value={crit.value===null?'':crit.value}  
                                                        placeholder={texts.placeholderText}
                                                        name={ckey}
                                                        key={'I'+ckey}
                                                        category={key}
                                                        onChange={handleChange}
                                                        onBlur={(e)=>
                                                            handleCheck(e,{name:ckey,category:key,value:e.target.value})
                                                        }
                                                    />
                                                </Grid.Column>
                                                :

                                                crit.parameterType==='numeric' ?   <>
                                                
                                                <Grid.Column width={16} key={`VAL${ckey}`} style={{'padding':'0'}}>
                                            
                                                    <Input 
                                                        type='number'
                                                        value={!crit.value?null:crit.value
                                                            .toLocaleString(config.locale,{minimumFractionDigits:crit.digits})
                                                        }
                                                        placeholder={texts.placeholderNumber}
                                                        name={ckey}
                                                        key={'I'+ckey}
                                                        category={key}
                                                        onChange={handleChange} 
                                                        onBlur={(e)=>
                                                            handleCheck(e,{name:ckey,category:key,value:e.target.value})
                                                        }
                                                    />
                                                </Grid.Column></>

                                                :  
                                               
                                                crit.parameterType==='currency' ?         
                                                
                                                    <Grid.Column width={16} key={`VAL${ckey}`} style={{'padding':'0'}}>
                                                
                                                        <Input 
                                                            type='number'
                                                            value={!crit.value?null:crit.value
                                                                .toLocaleString(config.locale,{minimumFractionDigits:2,currency:crit.currency})
                                                            }
                                                            placeholder={texts.placeholderCurrency}
                                                            name={ckey}
                                                            category={key}
                                                            key={'I'+ckey}
                                                            onChange={handleChange} 
                                                            onBlur={(e)=>
                                                                handleCheck(e,{name:ckey,category:key,value:e.target.value})
                                                            }
                                                        />
                                                    </Grid.Column>
                                                :
                                                ''
                                           
                                        }
                                    </Grid>
                                </Segment>

                            </Grid.Column>
                        ))
                    }
                </>))
            }
            </Grid>

           
     
    )

}

export default EditCategories
