import React, {useState, useEffect}  from 'react'
import {Popup} from 'react-leaflet'
import { Card} from 'semantic-ui-react'
import {useApi} from '@wollo-lib/kpe-context'
import {showDatum} from '@wollo-lib/wollo-react'



const MarkerPopup=(props)=>
{
    const [contact,setContact]=useState(null)
    const {config,texts,onClick,location}=props
    const {apiSelect}=useApi()

    useEffect(()=>{
        const  getContacts=async () =>
        {
            // the contacts can be an object ( external conatcts, not registered in the database) or an UID of a contact in the database
            // fetch now the ones, which are an UID string
            
            if(typeof (location.locationContacts[0])==='string' )
            {
                const fetched= await apiSelect(`/kpe20/person/${location.locationContacts[0]}`)
                if(fetched.success)
                {
                    const c=fetched.result
                    setContact(`${c.Title} ${c.Display}`)
                }
            }
            else if(location.locationContacts[0])
            {

                setContact(location.locationContacts[0].name)
            }
           
        }
        if(location &&  location.locationContacts && location.locationContacts.length>0)
            getContacts()
        else
            setContact(null)
    },[location])

    return(
        <Popup flowing>
            {/* <Card> */}
                <Card.Header as='h4'><a onClick={onClick}>{`${location.Title} ${location.Display}`}</a></Card.Header>
                { contact &&
                    <Card.Meta>{texts.contact}: {contact}</Card.Meta>
                }
                <Card.Meta>{texts.responsibleGroup}: {location.GroupTitle} {location.GroupDisplay}</Card.Meta>
            {/* </Card> */}
        </Popup>
    )
}

export default MarkerPopup